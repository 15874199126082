<template>
	<div class="full-height flex-column">
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="goBack"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ program.title }}</h2>
			</div>
			<div class="save">
				<button
					@click="doType"
					class="btn_save"
				>{{  text_type_btn }}</button>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-50 pb-80 full-height overflow-y-auto">
			<!-- 설정 -->
			<div class="subindex_wrap full-height">
				<div class="subindex_head">
					<div class="container">
						<div class="subindex_box_1">
							<h2>고정 게시판</h2>
							<ul class="settings_list">
								<li class="settings_list_item">
									<button>
										<div class="cell_area">
											<div class="cell_tit">{{  $language.common.cartelStory }}</div>

											<p class="toggle-group h_unset" @click="postBoardSetting(story_info)">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
											</p>
										</div>
									</button>
								</li>
								<li class="settings_list_item">
									<button>
										<div class="cell_area">
											<div class="cell_tit">{{  $language.common.notice }}</div>

											<p class="toggle-group h_unset" @click="postBoardSetting(notice_info)">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
												<span class="btn-toggle " :class="{ on: notice_info.board_state_code == 'CA00800002'}"><em class="hide">{{  notice_info.board_name }}</em></span>
											</p>
										</div>
									</button>
								</li>
								<li class="settings_list_item">
									<button>
										<div class="cell_area">
											<div class="cell_tit">{{  $language.common.oneLineCheering }}</div>

											<p class="toggle-group h_unset" @click="postBoardSetting(online_info)">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
												<span class="btn-toggle " :class="{ on: online_info.board_state_code == 'CA00800002'}"><em class="hide">{{  $language.common.join_confirm.name }}</em></span>
											</p>
										</div>
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- 유동적인 게시판 -->

				<div class="subindex_item">
					<div class="container">
						<div class="subindex_box_1">
							<h2> {{ $language.subscribe.btn_subscribe_add }} <!-- 구독 게시판 --></h2>
							<ul
								v-if="items_subscribe_list.length > 0"
								class="settings_list"
							>
								<template
									v-if="type == 'view'"
								>
									<template
										v-for="(item, index) in items_subscribe_list"
									>
										<li
											:key="'item_' + index"
											class="settings_list_item"
										>
											<button
											>
												<div class="cell_area">
													<div class="cell_tit">{{  item.board_name }}</div>
												</div>
											</button>
										</li>
									</template>
								</template>

								<draggable
									v-if="type == 'modify'"
									v-model="items_board_config"
									@start="setAction"
									@end="is_action = ''"
									handle=".handle"
								>
									<template
										v-for="(item, index) in items_subscribe_list"
									>
										<li
											v-if="item.board_type_code != 'CA00700001' && item.board_type_code != 'CA00700002'"
											:key="'item_' + index"
											class="settings_list_item "
											:class="{ order_action: is_action === index}"
										>
											<div class="cell_area">
												<div class="cell_tit_area">
													<button
														@click="onConfirm(item)"
														class="btn_cell-del"
													><span class="hide">{{  $language.common.delete }}</span></button>
													<span class="cell_tit">{{  item.board_name  }}</span>
													<button
														@click="toModify(item)"
														class="btn_cell-edit btn_s btn_outline_blue"
													>{{  $language.word.modify }}</button>
												</div>
												<!-- 순서이동 액션탭 -->
												<div class="cell_action handle">
													<span class="btn_order"><em class="hide">{{  $language.common.move_order }}</em></span>
												</div>
												<!-- 순서이동 액션탭 -->
											</div>
										</li>
									</template>
								</draggable>
							</ul>
							<div
								v-else
								class="pb-20"
							>
								<div class="text_guide text-center">
									<span class="cont_none ">{{ $language.common.no_board_list }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="subindex_item">
					<div class="container">
						<div class="subindex_box_1">
							<h2>{{ $language.subscribe.btn_subscribe_normal }} <!-- 일반 게시판 --></h2>
							<ul
								v-if="items_list.length > 0"
								class="settings_list"
							>

								<template
									v-if="type == 'view'"
								>
									<template
										v-for="(item, index) in items_list"
									>
										<li
											:key="'item_' + index"
											class="settings_list_item"
										>
											<button
											>
												<div class="cell_area">
													<div class="cell_tit">{{  item.board_name }}</div>
												</div>
											</button>
										</li>
									</template>
								</template>

								<draggable
									v-if="type == 'modify'"
									v-model="items_board_config"
									@start="setAction"
									@end="is_action = ''"
									handle=".handle"
								>
									<template
										v-for="(item, index) in items_list"
									>
										<li
											v-if="item.board_type_code != 'CA00700001' && item.board_type_code != 'CA00700002'"
											:key="'item_' + index"
											class="settings_list_item "
											:class="{ order_action: is_action === index}"
										>
											<div class="cell_area">
												<div class="cell_tit_area">
													<button
														@click="onConfirm(item)"
														class="btn_cell-del"
													><span class="hide">{{  $language.common.delete }}</span></button>
													<span class="cell_tit">{{  item.board_name  }}</span>
													<button
														@click="toModify(item)"
														class="btn_cell-edit btn_s btn_outline_blue"
													>{{  $language.word.modify }}</button>
												</div>
												<!-- 순서이동 액션탭 -->
												<div class="cell_action handle">
													<span class="btn_order"><em class="hide">{{  $language.common.move_order }}</em></span>
												</div>
												<!-- 순서이동 액션탭 -->
											</div>
										</li>
									</template>
								</draggable>
							</ul>
							<div
								v-else
								class="none_top character3_none mt-50"
							>
								<div class="text_guide text-center">
									<span class="cont_none ">{{ $language.common.no_board_list }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- //유동적인 게시판 -->
			</div>
			<!-- //설정 -->
		</div>
		<!-- 게시판추가 버튼-->
		<!-- //게시판추가 버튼-->

		<div
			v-if="type == 'view'"
			class="btn_area mt-auto "
			style="padding: 10px; border-top: 1rem #eee solid"
		>
			<button
				@click="$emit('to', { name: 'mafia125', params: { idx: $route.params.idx}})"
				class="btn_l btn_fill_blue"
			>{{  $language.notice.add }} <!-- 게시판 추가 --></button>
			<button
				@click="onSubscribe"
				class="btn_l btn_fill_blue"
			>{{  $language.subscribe.btn_subscribe_add }} <!-- 구독 게시판 추가 --></button>
		</div>

		<popup_confirm
			v-if="is_confirm"
			@click="deleteBoard(true)"
			@cancel="is_confirm = false"
		>
			<template v-slot:title>{{  $language.notice.article_delete }}</template>
			<template
				v-slot:main-txt
			>{{  $language.notice.delete_board_register }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.notice.delete_board_register_confirm }}</template>
		</popup_confirm>

		<popup_confirm
			v-if="is_on_subscribe"
			:not_cancel="true"

			@click="toSubscribe"
			@cancel="is_on_subscribe = false"
		>
			<template v-slot:title>{{  $language.subscribe.title_subscribe_add }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.subscribe.txt_subscribe_add_main }}</template>
		</popup_confirm>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import popup_confirm from '@/view/Layout/PopupConfirm'
export default {
	name: 'mafia122'
	, props: ['user']
	, components: {draggable, popup_confirm}
	, data: function(){
		return {
			program: {
				name: this.$language.notice.manage
				, title: this.$language.notice.manage
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'
				, from: this.$route.params.from
			}
			, item_cartel: {}
			, items_board_config: []
			, type: this.$route.params.type ? this.$route.params.type :'view'
			, is_action: ''
			, is_confirm: false
			, item_confirm: {}
			, item_search: {
				page_number: this.$language.base.page_number
				, pagerecnum: 30
			}
			, is_on_subscribe: false
		}
	}
	, computed: {
		text_type_btn: function(){
			let t = ''
			switch (this.type){
				case 'view':
					t = this.$language.word.modify
					break
				case 'modify':
					t = this.$language.common.complete
					break
			}
			return t
		}
		, board_list: function(){
			let t = [];
			let i = 1;
			this.items_board_config.filter((item) => {
				t.push({
					board_number: item.board_number
					, sort_seqen: i
				})
				i++
			})

			return t
		}
		, notice_info: function (){
			let t = {}

			this.items_board_config.filter((item) => {
				if(item.board_type_code == 'CA00700001'){
					t = item
				}
			})

			return t
		}
		, online_info: function (){
			let t = {}

			this.items_board_config.filter((item) => {
				if(item.board_type_code == 'CA00700002'){
					t = item
				}
			})

			return t
		}
		, story_info: function (){
			let t = {}

			this.items_board_config.filter((item) => {
				if(item.board_type_code == 'CA00700004'){
					t = item
				}
			})

			return t
		}
		, items_list: function(){
			let t = []
			this.items_board_config.filter( (item) => {
				if(item.board_type_code == 'CA00700003'){
					t.push(item)
				}
			})

			return t
		}
		, items_subscribe_list: function(){

			let t = []
			this.items_board_config.filter( (item) => {
				if(item.board_type_code == 'CA00700005'){
					t.push(item)
				}
			})

			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardConfig: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})
				if(result.success){
					this.items_board_config = result.data.board_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, updateBoardConfig: async function(){
			//
		}
		, doType: function(){
			switch (this.type){
				case 'view':
					this.type = 'modify'
					this.$router.push('/cartel/mafia122/' + this.$route.params.idx + '/modify')
					break
				case 'modify':
					this.postBoardSort()
					break
			}
		}
		, goBack: function(){
			switch (this.type){
				case 'view':
					this.$emit('to', { name: 'mafia074', params: {idx: this.$route.params.idx}})
					break
				case 'modify':
					this.type = 'view'
					break
			}
		}
		, onConfirm: function(item){
			this.item_confirm = item
			this.is_confirm = true
		}
		, deleteBoard: async function(){
			try{
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.post_board_config_delete
				if(this.item_confirm.board_type_code == 'CA00700005'){
					url = this.$api_url.api_path.post_subscribe_delete
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.item_confirm.board_number
					}
					, type: true
				})
				if(result.success){
					await this.getBoardConfig()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
			}
		}
		, setAction: function ($e){
			console.log('e', $e)
			this.is_action = $e.oldIndex
		}
		, postBoardSort: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_board_sort
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_list: this.board_list
					}
					, type: true
				})
				if(result.success){
					await this.getBoardConfig()
					this.type = 'view'
					await this.$router.push('/cartel/mafia122/' + this.$route.params.idx + '/view')
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
			}
		}
		, postBoardSetting: async function(item){

			if(item.board_state_code == 'CA00800001' || item.board_state_code == 'CA00800002'){
				this.$set(item, 'board_state_code', 'CA00800003')
			}else{
				this.$set(item, 'board_state_code', 'CA00800002')
			}

			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_board_setting
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, board_type_code: item.board_type_code
						, board_state_code: item.board_state_code
					}
					, type: true
				})
				if(result.success){
					//
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_confirm = false
			}
		}
		, onSubscribe: function(){
			this.is_on_subscribe = true
		}
		, toSubscribe: function(){
			this.$bus.$emit('to', { name: 'SubscribeSetting', params: { idx: this.$route.params.idx }})
		}
		, toModify: function(item){
			if(item.board_type_code == 'CA00700005'){
				this.$bus.$emit('to', { name: 'SubscribeSetting', params: { idx: this.$route.params.idx, b_id: item.board_number}})
			}else{
				this.$bus.$emit('to', { name: 'mafia125', params: { idx: this.$route.params.idx, b_id: item.board_number}})
			}
		}
	}
	,created() {
		this.$bus.$emit('onLoad', this.program)
		this.getCartel()
		this.getBoardConfig()
	}
}
</script>

